<template>
  <div>
    <CRow class="text-black">
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCol xl="3" class="padding-0">
        <CCard class="col-xl-12 padding-0">
          <CCardHeader>
              <CRow class="margin-0 text-black">
                <CCol xl="11" class="padding-0">
                    Latest Chats
                </CCol>
                <CCol xl="1" class="padding-0" style="cursor: pointer; text-align: right;" @click="listGroup">
                    <v-icon name="users"/>
                </CCol>
              </CRow>
          </CCardHeader>
          <CCardBody class="padding-0">
            <CCol xl="12" style="text-align: center;" v-if="isLoading">
                <CSpinner
                    color="info"
                    style="width:1rem;height:1rem;"
                />
            </CCol>
            <CListGroup v-if="this.listchats.length > 0" class="list-height">
              <CListGroupItem tag="button" class="padding-5" v-for="(lcs, idx) in listchats" :key="idx" @click="showChats(lcs)">
                  <CRow class="margin-0 text-black">
                    <CCol xl="2" class="padding-0">
                        <div :class="{'c-avatar':true, 'bd-avatar':lcs.picture?true:false}">
                            <img :src="lcs.picture?lcs.picture:avaurl" class="c-avatar-img-h100" alt=""/>
                        </div>
                    </CCol>
                    <CCol xl="10" class="padding-0">
                        <div class="d-flex w-100 justify-content-between">
                            <strong>{{filterLatest(lcs.sender, 30)}}</strong>
                            <small>{{lcs.send_at?formatDatelist(lcs.send_at):'-'}}</small>
                        </div>
                        <CRow class="margin-0">
                            <CCol xl="11" class="padding-0">
                                {{filterLatest(filterLatestmsg(lcs.message), 30)}}
                            </CCol>
                            <CCol xl="1" class="padding-0">
                                <CBadge v-if="lcs.unread_count>0" color="primary" shape="pill">{{lcs.unread_count}}</CBadge>
                            </CCol>
                        </CRow>
                    </CCol>
                  </CRow>
              </CListGroupItem>
            </CListGroup>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xl="9" class="padding-0">
        <CCard class="col-xl-12 padding-0">
          <CCardHeader>
            <CRow class="margin-0 text-black">
                <CCol xl="11" class="padding-0">
                    {{sender.namechat}}
                </CCol>
                <CCol xl="1" class="padding-0">
                  <CDropdown
                    v-if="showchat.room_type=='group'"
                    color="secondary"
                    placement="bottom"
                    class="float-right"
                  >
                    <template #toggler>
                      <CButton :color="'secondary'" class="btn-xsm btn-sm">
                        <v-icon name="caret-down"/>
                      </CButton>
                    </template>
                    <CDropdownItem @click="viewGroup(sender.uuid)">
                      <v-icon name="eye" class="mr-1"/>View
                    </CDropdownItem>
                    <CDropdownItem @click="editGroup(sender.uuid)">
                      <v-icon name="pencil-alt" class="mr-1 v-mid"/> Edit
                    </CDropdownItem>
                    <CDropdownItem @click="removeGroup(sender.uuid)">
                      <v-icon name="trash" class="mr-1 v-mid"/> Delete
                    </CDropdownItem>
                  </CDropdown>
                </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="padding-0">
            <!-- <CCol xl="12" style="text-align: center;" v-if="isSLoading">
                <CSpinner
                    color="info"
                    style="width:1rem;height:1rem;"
                />
            </CCol> -->
            <div class="direct-chat-messages" id="listofmessage" v-if="this.showingchats.length > 0">
                <div :class="{'direct-chat-msg':true, 'right':profiles.id==scs.sender_id}" v-for="(scs, idx) in showingchats" :key="idx">
                    <div class="direct-chat-infos clearfix">
                        <span :class="{'direct-chat-name':true, 'float-left':profiles.id!=scs.sender_id, 'float-right':profiles.id==scs.sender_id}">
                            {{scs.sender.name}}
                        </span>
                        <small :class="{'direct-chat-timestamp':true, 'float-right':profiles.id!=scs.sender_id, 'float-left':profiles.id==scs.sender_id}">
                            {{ formatTimechat(scs.send_at) }}
                        </small>
                    </div>
                    <img class="direct-chat-img" :src="scs.sender.profile_picture?scs.sender.profile_picture:avaurl">
                    <div :class="{'direct-chat-text':true, 'bg-yellow':profiles.id==scs.sender_id}">
                        <span v-for="(spmsg, ism) in withBrTags(scs.message)" :key="idx+'-'+ism">{{ spmsg }}<br/></span>
                    </div>
                </div>
            </div>
          </CCardBody>
          <CCardFooter v-if="this.showchat.room_id > 0">
            <CForm>
              <div role="group" :class="{'dis-textarea-h':newline<2, 'padding-0':true, 'margin-0':true, 'form-group':true}">
                <div class="input-group">
                  <textarea v-model="sendtext" @keydown="sendchatHandler" placeholder="Type Message ..." :rows="newline" class="form-control" />
                  <div class="input-group-append">
                    <button type="button" @click="sendMychat" class="btn text-black btn-warning">Send</button>
                  </div>
                </div>
              </div>
            </CForm>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Modal Delete"
      :show.sync="modalDelete"
      size="sm"
    >
      <span>Are you sure remove this group ?</span>
      <template #header>
        <h6 class="modal-title">Remove Group</h6>
        <CButtonClose @click="modalDelete = false"/>
      </template>
      <template #footer>
        <CButton @click="modalDelete = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="destroyData(delId)" color="danger">Yes</CButton>
      </template>
    </CModal>
    <CModal
      title="Modal List"
      :show.sync="modalList"
      size="sm"
    >
      <CCol v-if="isLLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CCol v-if="!isLLoading && this.groups.length < 1" xl="12" style="text-align: center;">
        <span>Empty</span>
      </CCol>
      <CListGroup v-if="this.groups.length > 0" style="overflow-y: auto; margin: -1rem;">
        <CListGroupItem tag="button" class="padding-5" v-for="(lgr, idg) in groups" :key="idg" 
          @click="showGroupChats({room_type: 'group', room_id: lgr.id})">
            <CRow class="margin-0 text-black">
              <CCol xl="2" class="padding-0">
                  <div class="c-avatar">
                      <img :src="lgr.group_picture?lgr.group_picture:avaurl" class="c-avatar-img-h100" alt=""/>
                  </div>
              </CCol>
              <CCol xl="10" class="padding-0 mt-1">
                  <CRow class="margin-0">
                      <CCol xl="12" class="padding-0">
                          <strong>{{filterLatest(lgr.name, 30)}}</strong>
                      </CCol>
                  </CRow>
              </CCol>
            </CRow>
        </CListGroupItem>
      </CListGroup>
      <template #header>
        <CRow class="modal-title col-12">
          <CCol class="padding-0" xl="11">List Group</CCol>
          <CCol xl="1" class="padding-0" style="cursor: pointer; text-align: right;" @click="addGroup">
              <v-icon name="plus-circle"/>
          </CCol>
        </CRow>
        <CButtonClose @click="modalList = false"/>
      </template>
      <template #footer>
        <CButton @click="modalList = false" color="light" class="text-left">Close</CButton>
      </template>
    </CModal>
    <CModal
      title="Modal Form"
      :show.sync="modalForm"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CForm v-if="!isMLoading">
        <CInput
          label="Group Name"
          :description="ferrors.name"
          placeholder="Please fill group name"
          type="text"
          vertical
          v-model="form.name"
          :readonly="frmaction=='view'?true:false"
        />
        <CTextarea
          label="Description"
          :description="ferrors.description"
          placeholder="Please fill description"
          vertical
          rows="4"
          v-model="form.description"
          :readonly="frmaction=='view'?true:false"
        />
        <div class="form-group" role="group">
        <label>Member</label>
        <multiselect
            @input="changeMember"
            v-model="value_members"
            :options="members"
            track-by="value"
            label="label"
            :multiple="true"
            placeholder="Choose Member"
            :close-on-select="false"
            :clear-on-select="false"
            :disabled="frmaction=='view'?true:false">
        </multiselect>
        <small class="form-text text-muted w-100">{{ferrors.members}}</small>
        </div>
        <CRow v-if="frmaction=='view'?false:true">
            <CCol xl="12"><label>Group Picture</label></CCol>
            <CCol xl="1">
                <CButton color="danger" class="btn-sm" v-on:click="onFileRemoved"><v-icon name="times" class="v-mid"/></CButton>
            </CCol>
            <CCol xl="11">
                <input ref="inputImage" type="file" @change="onFileChanged" />
            </CCol>
            <CCol xl="12"><span>{{ferrors.group_picture}}</span></CCol>
        </CRow>
        <div id="preview">
            <img v-if="url" :src="url" />
        </div>
      </CForm>
      <template #header>
        <h6 class="modal-title">{{frmId==''?'Add':frmaction!='view'?'Edit':'Show'}} Group</h6>
        <CButtonClose @click="modalForm = false"/>
      </template>
      <template #footer>
        <CButton @click="modalForm = false" color="light" class="text-left">Cancel</CButton>
        <CButton v-if="frmaction=='view'?false:true" @click="frmaction=='add'?postData():updateData(frmId)" color="info">{{frmId==''?'Save':'Update'}}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'ChatGroup',
  components: {},
  props: {
      roomid: {
        type: Number,
        default () {
            return this.$route.params.roomid?this.$route.params.roomid:0
        }
      },
      roomtype: {
        type: String,
        default () {
            return this.$route.params.roomtype?this.$route.params.roomtype:'personal'
        }
      },
      valuemembers: {
        type: Array,
        default: () => []
      },
  },
  data () {
    return {
        isLoading: false, isSLoading: false, isCLoading: false,
        isMLoading: false, isLLoading: false, 
        modalForm: false, modalDelete: false, modalList: false,
        delId: '', frmId: '', frmaction: 'view',
        avaurl: 'img/avatars/profile_img.svg',
        profiles: {
            id: 0,
        },
        value_members: this.valuemembers,
        formData: null,
        url: null,
        img_api: '',
        is_removeimage: null,
        members: [],
        listchats: [],
        showingchats: [],
        groups: [],
        showchat: {
            room_type: '',
            room_id: ''
        },
        sendchat: {
            room_type: '',
            room_id: '',
            message: ''
        },
        errors: {
            room_type: '',
            room_id: '',
            message: ''
        },
        form: {
            uuid: '',
            name: '', 
            description: '',
            members: [],
            group_picture: null,
        },
        ferrors: {
            name: '', 
            description: '',
            members: '',
            group_picture: ''
        },
        sender: {
            namechat: 'Messages',
            uuid: ''
        },
        sendtext: '',
        vallist: null,
        newline: 1
    }
  },
  watch: {
    modalForm: {
      handler: function (val, oldVal) {
        if(!val){
          this.frmId = '', this.frmaction = 'view',
          this.value_members = [],
          this.form = { 
            uuid: '',
            name: '', 
            description: '',
            members: [],
            group_picture: null,
          }
          this.ferrors = { 
            name: '',
            description: '',
            members: '',
            group_picture: ''
          }
        }
      }
    },
    modalDelete: {
      handler: function (val, oldVal) {
        if(!val){ this.delId = '' }
      }
    },
  },
  async created() {
    await this.checkRoom(this.roomid, this.roomtype)
    await this.showUser()
    await this.reRender()
    await this.getMember()
    await this.onLoad()
  },
  beforeDestroy() {
    clearInterval(this.intervalChats)
    clearInterval(this.intervalLatest)
  },
  methods: {
    ...mapActions('chatgroup', ['indexCGP', 'showCGP', 'storeCGP', 'storeGRP', 'listGRP', 'showGRP', 'updateGRP', 'destroyGRP']),
    ...mapActions('contact', ['indexCTC']),
    onLoad() {
      this.intervalChats = setInterval(this.listshowChats, 10000)
      this.intervalLatest = setInterval(this.getLatest, 10000)
    },
    reRender(){
      this.isLoading = true
      this.listchats = []
      this.getLatest()
    },
    getLatest(){
      this.indexCGP().then((response) => {
        this.listchats = response.length > 0 ? response : []
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    formatDatelist(value) {
        return moment(String(value)).format('DD/MM/YY')
    },
    formatTimechat(value) {
        return moment(String(value)).format('DD MMM h:mm a')
    },
    showChats(val) {
      this.vallist = val, this.newline = 1, this.sendtext = ''
      this.listshowChats(val)
    },
    async listshowChats(val) {
      if(!val) var val = this.vallist
      if(this.vallist) {
        this.isSLoading = true
        if(val.room_id!=this.showchat.room_id || val.room_type!=this.showchat.room_type) {
          this.showingchats = []
        }
        this.showchat = {
          room_type: val.room_type,
          room_id: val.room_id
        }
        await this.showCGP(this.showchat).then((response) => {
          this.sender = {
              namechat: response.sender?response.sender.name:'Messages',
              uuid: response.sender?response.sender.uuid:''
          }
          var messages = response.chats.length > 0 ? response.chats : []
          // if(val.room_type=='group') {
          //     this.showingchats = this.filterMessages(messages)
          // } else {
              this.showingchats = messages
          // }
          this.isSLoading = false
          this.scrollToEnd()
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error })
          this.isSLoading = false
        })
      }
    },
    showUser() {
      this.profiles = this.$store.state.userdata
    },
    sendchatHandler(e) {
      var count_nw = (this.sendtext.match(/(?:\r\n|\r|\n)/g) || []).length
      if(count_nw>0 && count_nw<4) this.newline = count_nw+1
      if(count_nw==0) this.newline = 1

      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.sendMychat();
      } else if (e.keyCode === 13 && e.shiftKey) { }
    },
    sendMychat() {
      this.isCLoading = true
      this.sendchat = {
        room_type: this.showchat.room_type,
        room_id: this.showchat.room_id,
        message: this.sendtext
      }
      this.storeCGP(this.sendchat).then((response) => {
        this.showChats(this.showchat)
        this.isCLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isCLoading = false
      })
    },
    withBrTags(val) {
      var msg = val.replace(/(?:\r\n|\r|\n)/g, 'iw&#92;cc')
      return msg.split("iw&#92;cc")
    },
    listGroup(){
      this.modalList = true
      this.showList()
    },
    showGroupChats(val) {
      this.showChats(val)
      this.modalList = false
    },
    viewGroup(uuid){
      this.frmaction = 'view'
      this.frmId = uuid
      this.modalForm = true
      this.showData(uuid)
    },
    addGroup() {
      this.frmaction = 'add'
      this.modalList = false
      this.modalForm = true
    },
    editGroup(uuid){
      this.frmaction = 'edit'
      this.frmId = uuid
      this.modalForm = true
      this.showData(uuid)
    },
    removeGroup(uuid){
      this.modalDelete = true
      this.delId = uuid
    },
    showList(){
      this.isLLoading = true
      var tableconfig = {
        _method: 'GET'
      }
      this.listGRP(tableconfig).then((response) => {
        this.groups = response.data.length > 0 ? response.data : []
        this.isLLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLLoading = false
      })
    },
    showData(uuid){
      this.isMLoading = true
      this.showGRP(uuid).then((response) => {
        this.form = response
        this.url = response.group_picture
        this.img_api = response.group_picture
        this.form.group_picture = null
        this.filterMember(response.members)
        this.generateFormData()
        this.isMLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isMLoading = false
      })
    },
    postData(){
      this.isMLoading = true
      this.generateFormData()
      this.storeGRP(this.formData).then((response) => {
        this.reRender()
        this.isMLoading = false
        this.modalForm = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getFError(error.data)
        this.isMLoading = false
      })
    },
    updateData(uuid){
      this.isMLoading = true
      this.generateFormData()
      this.updateGRP(this.formData).then((response) => {
        this.reRender()
        this.isMLoading = false
        this.modalForm = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isMLoading = false
      })
    },
    destroyData(uuid){
      this.modalDelete = false
      this.destroyGRP(uuid).then((response) => {
        this.reRender()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    changeMember(val) {
      this.value_members = val
      this.form.members = val.map(function (obj) {
        return obj.value
      })
    },
    filterMember(val) {
      var id = this.profiles.id
      if(this.frmaction=='edit') {
        val = val.filter(function(item) {
          return item.id!=id
        })
      }
      this.value_members = val.map(function (obj) {
        return { value: obj.id, label: obj.name }
      })
    },
    async getMember(){
        this.isMLoading = true
        var tableconfig = {
            _method: 'GET'
        }
        await this.indexCTC(tableconfig).then((response) => {
            response.data.forEach((item, index) => {
                this.members[index] = { value: item.id, label: item.name }
            })
            this.isMLoading = false
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
            this.isMLoading = false
        })
    },
    onFileChanged(e) {
      if(e.target.files[0].size<=1024000) {
        this.form.group_picture = e.target.files[0]
        this.url = URL.createObjectURL(this.form.group_picture)
        this.ferrors.group_picture = ''
      } else {
        this.ferrors.group_picture = 'File max 1MB'
      }
      console.log('size', e.target.files[0].size) // 2697333 B
    },
    onFileRemoved(e) {
      if(this.img_api) {
        this.is_removeimage = 'true'
      } else {
        this.is_removeimage = null
      }
      this.form.group_picture = null
      this.url = null
    },
    checkRoom(id, type) {
      if(id>0) {
        this.showchat = {
          room_type: type,
          room_id: id
        }
        this.showChats(this.showchat)
      }
    },
    filterMessages(val) {
      var id = this.profiles.id
      var fmsg = val.filter(function(item) {
        return item.receiver_id==id
      })
      return fmsg
    },
    filterLatest(value, size) {
        if (!value) return '-'
        value = value.toString()
        return value.length > size ? value.slice(0, size) + '...' : value
    },
    filterLatestmsg(val) {
      var msg = val.replace(/(?:\r\n|\r|\n)/g, 'iw&#92;cc')
      var nmsg = msg.split("iw&#92;cc")
      return nmsg[0]
    },
    scrollToEnd() {
        if(!this.isSLoading && this.showingchats.length > 0) {
            setTimeout(() => {
                var container = this.$el.querySelector("#listofmessage")
                if(container) { container.scrollTop = container.scrollHeight }
            }, 500)
        }
    },
    generateFormData(){
      this.formData = new FormData()
      this.formData.append('uuid', this.form.uuid)
      this.formData.append('name', this.form.name)
      this.formData.append('description', this.form.description?this.form.description:'')
      this.value_members.forEach((item, index) => {
        this.formData.append('members[]', item.value)
      })
      if(this.frmaction=='add') {
        this.formData.append('members[]', this.profiles.id)
      }
      if(this.form.group_picture) {
        this.formData.append('group_picture', this.form.group_picture)
      }
      if(this.is_removeimage) {
        this.formData.append('is_removeimage', this.is_removeimage)
      }
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) {
        this.errors.room_type = data.errors.room_type ? data.errors.room_type[0]+', ' : ''
        this.errors.room_id = data.errors.room_id ? data.errors.room_id[0]+', ' : ''
        this.errors.message = data.errors.message ? data.errors.message[0] : ''

        this.flashMessage.error({ title: 'Error', message: this.errors.room_type+this.errors.room_id+this.errors.message })
      }}
    },
    getFError(data){
      if(typeof(data) == 'object'){
        this.ferrors.name = data.errors.name ? data.errors.name[0] : ''
        this.ferrors.description = data.errors.description ? data.errors.description[0] : ''
        this.ferrors.group_picture = data.errors.group_picture ? data.errors.group_picture[0] : ''
        this.ferrors.members = data.errors.members ? data.errors.members[0] : ''
      }
    },
  }
}
</script>
<style scoped>
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #preview img {
    max-width: 40%;
    max-height: 40%;
  }
</style>